import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import publisherImg from "../images/logo_cover.jpg";
import RelatedServices from "../components/RelatedServices";
import Breadcrumbs from "../components/Breadcrumbs";
import { ShareBar } from "../components/share-bar";

const gfm = require("remark-gfm");

const BlogPost = ({ data }) => {
  const { strapiBlog } = data;

  const {
    Snippet,
    Title,
    dateReadable,
    metadescription,
    metatitle,
    slug,
    published_at,
    Content,
    image,
  } = strapiBlog;

  const author = "Christine Pilla";
  const websiteurl = "https://www.localtier.com";
  const companyname = "LocalTier";

  const imageData = getImage(image && image.localFile);

  return (
    <Layout bottomBorder={true}>
      <SEO
        title={metatitle}
        description={metadescription}
        img={image.localFile.childImageSharp.seoImage.images.fallback.src}
      />
      <div className="bg-white border-b border-gray-100">
        <nav
          className="flex max-w-7xl mx-auto px-4 md:px-6 py-3"
          aria-label="Breadcrumb"
        >
          <Breadcrumbs
            links={[
              { to: "/", label: "Home" },
              { to: "/blog/", label: "Blog" },
              { to: `/blog/${slug}/`, label: Title },
            ]}
          />
        </nav>
      </div>

      <section className="section px-4 md:px-6 bg-gray-50">
        <Helmet>
          <script type="application/ld+json">{`
                  {
                  "@context": "http://schema.org",
                  "@type": "BlogPosting",
                  "mainEntityOfPage":{
                  "@type":"WebPage",
                  "@id": "${websiteurl + slug}"
                  },
                  "headline": ${Title},
                 
                  "datePublished": "${published_at}",
                  "dateModified": "${published_at}",
                  "author": {
                    "@type": "Person",
                    "name": "${author}"
                  },
                  "publisher": {
                    "@type": "Organization",
                    "name": "${companyname}",
                    "logo": {
                      "@type": "ImageObject",
                      "url": "${publisherImg}",
                      "width": 600,
                      "height": 60
                    }
                  },
                  "description": "${Snippet}"
                  }
                  `}</script>
        </Helmet>

        <div className="flex flex-wrap max-w-7xl mx-auto py-10">
          <div className="mx-auto w-full md:w-2/3 p-0 md:pr-16">
            <GatsbyImage
              alt={Title}
              image={imageData}
              className="w-full object-cover mb-6"
            />

            <h1 className="text-2xl sm:text-2xl md:text-4xl mb-2 font-bold">
              {Title}
            </h1>
            <span className="text-gray-800 mb-8 flex items-center">
              {`${author} on ${dateReadable}`}
            </span>

            <ShareBar siteurl={websiteurl} post={strapiBlog} />

            {/* <Img
  fluid={post.frontmatter.featuredimage.childImageSharp.fluid}
  alt={post.frontmatter.title}
  className="shadow-lg w-full object-cover mb-8 mt-0 border-4 border-white"
  style={{
    height: '300px',
    objectFit: 'cover',
  }}
/> */}

            <ReactMarkdown
              className={"prose max-w-4xl w-full"}
              plugins={[gfm]}
              children={Content}
              allowDangerousHtml
            />
          </div>

          {/* <div className="w-full md:w-1/3 p-0 md:pl-6">
           

          </div> */}
        </div>
      </section>
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($strapiId: Int!) {
    strapiBlog(strapiId: { eq: $strapiId }) {
      id
      published_at(formatString: "YYYY-MM-DD")
      dateReadable: published_at(formatString: "MMMM DD, YYYY")
      Title
      Content
      Snippet
      metadescription
      metatitle
      slug
      image {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(height: 350, placeholder: BLURRED)
            seoImage: gatsbyImageData(
              width: 1200
              height: 630
              quality: 100
              placeholder: NONE
            )
          }
        }
      }
    }
  }
`;
